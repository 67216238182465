
/* -------------------------------------------------------------------------
 * SPACES
 *
 * Base spaces naming and value pairing
 * The values here are for sample proposes, consider add your owns
 *
 * Modifying these variables can break the vertical rhythm.
 * Should be modified with caution.
 * Values should be expressed in PX.
 *
 * If you need a relative unit based on that values, use the core functions
 * on used context.
 *
 */


// Main space

$f-space:                     24px !default;

// Space variants

$f-space--none:               0 !default;
$f-space--tiny:               round($f-space * .25) !default;
$f-space--small:              round($f-space * .5) !default;
$f-space--medium:             round($f-space * 1) !default;
$f-space--large:              round($f-space * 2) !default;
$f-space--huge:               round($f-space * 4) !default;
$f-space--giant:              round($f-space * 5) !default;


// Spaces map

$f-spaces: (
        "none":                     $f-space--none,
        "tiny":                     $f-space--tiny,
        "small":                    $f-space--small,
        "medium":                   $f-space--medium,
        "large":                    $f-space--large,
        "huge":                     $f-space--huge,
) !default;


////////////////////////////////////////////////////////////////////////////////
//                               W A R N I N G                                //
//                  DO NOT MODIFY ANYTHING BEYOND THIS POINT                  //
////////////////////////////////////////////////////////////////////////////////

/* SAFE CHECKING
 * Check that the chosen values are pixel numbers.
 */

@each $_space__name, $_space__value in $f-spaces {

  @if (type-of($_space__value) == number) {

    @if ($_space__name != "none") {

      @if (unit($_space__value) != "px") {
        @error "$f-spaces: `#{$_space__name}` needs to be a pixel value.";
      }
    }

  } @else {
    @error "$f-spaces: `#{$_space__name}` needs to be a number.";
  }

}