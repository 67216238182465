
/* -------------------------------------------------------------------------
 * COLORS VENDOR
 *
 * Define all vendor colors
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main vendor colors

$f-color__vendor-codepen:                 #ffffff !default;
$f-color__vendor-facebook:                #3b5998 !default;
$f-color__vendor-github:                  #4078c0 !default;
$f-color__vendor-instagram:               #3f729b !default;
$f-color__vendor-linkedin:                #0976b4 !default;
$f-color__vendor-twitter:                 #00aced !default;
