
/* -------------------------------------------------------------------------
 * FONTS
 *
 * Font families & fallback
 * The values here are for sample proposes, consider add your owns
 *
 */

$f-font-family__primary--main:         'Montserrat' !default;
$f-font-family__primary--fallback:      Arial, Helvetica, sans-serif !default;

$f-font-family__secondary--main:        'Raleway' !default;
$f-font-family__secondary--fallback:    Impact, Charcoal, sans-serif !default;

// Main font families

$f-font-family__primary:                $f-font-family__primary--main, $f-font-family__primary--fallback !default;
$f-font-family__secondary:              $f-font-family__secondary--main, $f-font-family__secondary--fallback !default;