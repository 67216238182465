/* -------------------------------------------------------------------------
 * DOCUMENT
 *
 * Based on inuitcss reference
 * Adapted to haiticss scoped mode
 *
 * Simple page-level setup
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward `jumps` when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 *
 */

// VARS
// -----------------------------------------

$e-document__body--font-family:         $f-font-family__primary !default;
$e-document__body--font-size:           $s-core__font-size !default;
$e-document__body--font-weight:         300 !default;
$e-document__body--line-height:         $f-line-height--small !default;
$e-document__body--color:               $f-color__neutral-grey--dark !default;
$e-document__body--bgcolor:             $f-color__neutral-white !default;


// OUTPUT
// -----------------------------------------

html {
  font-family: $e-document__body--font-family;
  color: $e-document__body--color;
  background-color: $e-document__body--bgcolor;
  font-size: s-core-strip-units(s-core-px-to-rem($e-document__body--font-size, 16px)) * 1em;
  line-height: $e-document__body--line-height;
  font-weight: $e-document__body--font-weight;
  overflow-y: scroll;
  min-height: 100%;
}