/* -------------------------------------------------------------------------
 * SECTION
 *
 */


// VARS
// ----------------------------------------
$c-section---padding:                             $f-space--medium !default;

$c-section--dark--color:                          $f-color__neutral-white !default;
$c-section--dark--background-color:               $f-color__neutral-black !default;

// OUTPUT
// ----------------------------------------

.c-section {
  padding: $c-section---padding;
}

.c-section--full-screen {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.c-section--with-image {
  overflow: hidden;

  .c-section__children {
    z-index: 1;
    position: relative;
  }

  .c-section__bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    min-width: 100vw;
    height: 100vh;
    margin-left: auto;
    width: auto;
    opacity: .15;
  }
}

.c-section--dark {
  background-color: $c-section--dark--background-color;
  color: $c-section--dark--color;
}