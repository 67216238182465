
/* -------------------------------------------------------------------------
 * COLORS NEUTRAL
 *
 * Neutral colors are the ones without saturation
 * The values here are for sample proposes, consider add your owns
 *
 */


// Black & White

$f-color__neutral-white:                #fff !default;
$f-color__neutral-black:                #000 !default;

// Grey shades (Mid tones are missing intentionally)

$f-color__neutral-grey--ultra-light:    #fafafa !default;
$f-color__neutral-grey--light:          #efefef !default;
$f-color__neutral-grey--semi-light:     #dedede !default;
$f-color__neutral-grey:                 #c6c6c6 !default;
$f-color__neutral-grey--semi-dark:      #6e6e6f !default;
$f-color__neutral-grey--dark:           #2a2628 !default;
$f-color__neutral-grey--ultra-dark:     #000000 !default;

// Transparent colors

$f-color__neutral-white--transparent:   rgba($f-color__neutral-white, 0) !default;
$f-color__neutral-black--transparent:   rgba($f-color__neutral-black, 0) !default;