/* -------------------------------------------------------------------------
 * APP MAIN STYLES
 *
 */

@import "./settings/settings";
@import "./foundations/foundations";
@import "./base/base";
@import "./elements/elements";
@import "./components/components";
@import "./templates/templates";
