
/* -------------------------------------------------------------------------
 * HEADINGS
 *
 * Define some minimal heading styles
 *
 */

// VARS
// -----------------------------------------

$e-headings__font-weight:         400 !default;
$e-headings__font-family:         $f-font-family__secondary !default;

$e-headings__font-size--h1:       $f-font-size--xxl !default;
$e-headings__font-size--h2:       $f-font-size--xl !default;
$e-headings__font-size--h3:       $f-font-size--l !default;
$e-headings__font-size--h4:       $f-font-size--m !default;
$e-headings__font-size--h5:       $f-font-size--s !default;
$e-headings__font-size--h6:       $f-font-size--xs !default;

$e-headings__line-height:         $f-line-height--tiny !default;

$e-headings__margin-bottom:       $g-reset__spacing--vertical !default;



// OUTPUT
// -----------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $e-headings__line-height;
  margin-bottom: $e-headings__margin-bottom;
  font-weight: $e-headings__font-weight;
  font-family: $e-headings__font-family;
}


h1 {
  font-size: s-core-px-to-rem($e-headings__font-size--h1);
}

h2 {
  font-size: s-core-px-to-rem($e-headings__font-size--h2);
}

h3 {
  font-size: s-core-px-to-rem($e-headings__font-size--h3);
}

h4 {
  font-size: s-core-px-to-rem($e-headings__font-size--h4);
}

h5 {
  font-size: s-core-px-to-rem($e-headings__font-size--h5);
}

h6 {
  font-size: s-core-px-to-rem($e-headings__font-size--h6);
}