/* -------------------------------------------------------------------------
 * PARAGRAPHS
 *
 * Appearance & behaviour for paragraphs
 *
 */

$e-paragraphs__spacing-vertical:            $g-reset__spacing--vertical !default;
$e-paragraphs__space-within:                $f-space--small !default;


// OUTPUT
// -----------------------------------------

p + p {
  margin-top: ($e-paragraphs__spacing-vertical - $e-paragraphs__space-within) * -1;
}