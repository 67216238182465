/* -------------------------------------------------------------------------
 * AVATAR
 *
 */

// VARS
// ------------------------------------------

$c-avatar--font-size:                               $f-font-size--l !default;
$c-avatar--padding:                                 $f-space--small !default;

$c-avatar__picture--size:                           150px !default;
$c-avatar__picture--radius:                         $f-radius--ellipsis !default;
$c-avatar__picture--margin-bottom:                  $f-space--medium !default;

$c-avatar__name--font-size:                         $f-font-size--xl !default;
$c-avatar__name--font-family:                       $f-font-family__secondary !default;

$c-avatar__description--line-height:                $f-line-height--small !default;


// OUTPUT
// ------------------------------------------

.c-avatar {
  font-size: $c-avatar--font-size;
  padding: $c-avatar--padding;
}

.c-avatar__picture {
  width: $c-avatar__picture--size;
  height: $c-avatar__picture--size;
  margin: 0 auto;
  border-radius: $c-avatar__picture--radius;
  overflow: hidden;
  margin-bottom: $c-avatar__picture--margin-bottom;
}

.c-avatar__name {
  font-size: $c-avatar__name--font-size;
  font-family: $c-avatar__name--font-family;
  font-weight: 600;
  text-transform: uppercase;
}

.c-avatar__description {
  font-weight: 500;
  line-height: $c-avatar__description--line-height;
}

.c-avatar__description-item {
  display: block;
}