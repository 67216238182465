/* -------------------------------------------------------------------------
 * AVATAR
 *
 */

// VARS
// ------------------------------------------

$c-icon-row--font-size:                             $f-font-size--xl !default;

$c-icon-row__link--margin-h:                        $f-space--small !default;
$c-icon-row__link-color:                            $f-space--small !default;
$c-icon-row__link-color--hover:                     $f-space--small !default;

$c-icon-row__link-color--codepen--hover:            $f-color__vendor-codepen !default;
$c-icon-row__link-color--github--hover:             $f-color__vendor-github !default;
$c-icon-row__link-color--instagram--hover:          $f-color__vendor-instagram !default;
$c-icon-row__link-color--linkedin--hover:           $f-color__vendor-linkedin !default;
$c-icon-row__link-color--twitter--hover:            $f-color__vendor-twitter !default;


// OUTPUT
// ------------------------------------------

.c-icon-row {
  font-size: $c-icon-row--font-size;
}

.c-icon-row__link {
  margin-left: $c-icon-row__link--margin-h;
  margin-right: $c-icon-row__link--margin-h;
  color: $f-color__neutral-grey--semi-dark;
}

.c-icon-row__link--codepen:hover {
  color: $c-icon-row__link-color--codepen--hover;
}

.c-icon-row__link--github:hover {
  color: $c-icon-row__link-color--github--hover;
}

.c-icon-row__link--instagram:hover {
  color: $c-icon-row__link-color--instagram--hover;
}

.c-icon-row__link--linkedin:hover {
  color: $c-icon-row__link-color--linkedin--hover;
}

.c-icon-row__link--twitter:hover {
  color: $c-icon-row__link-color--twitter--hover;
}

