/* -------------------------------------------------------------------------
 * LINKS
 *
 * Define some minimal links styles
 *
 */


// VARS
// -----------------------------------------

$e-links__color:                          $f-color__digital-blue !default;
$e-links__hover-color:                    $f-color__digital-blue !default;

$e-links__decoration:                     none !default;
$e-links__font-weight:                    400 !default;
$e-links__hover-decoration:               underline !default;


// OUTPUT
// -----------------------------------------

a {
  color: $e-links__color;
  text-decoration: $e-links__decoration;
  font-weight: $e-links__font-weight;

  @if ($e-links__hover-color != $e-links__color or $e-links__decoration != $e-links__hover-decoration) {
    &:hover {

      @if ($e-links__hover-color != $e-links__color) {
        color: $e-links__hover-color;
      }

      @if ($e-links__decoration != $e-links__hover-decoration) {
        text-decoration: $e-links__hover-decoration;
      }
    }
  }
}