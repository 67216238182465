/* -------------------------------------------------------------------------
 * BOX-SIZING
 *
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */

// VARS
// -------------------------------------------------
$g-box-sizing:          border-box !default;


// OUTPUT
// -------------------------------------------------

html {
  box-sizing: $g-box-sizing;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}
